// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Sitrader from './Sitrader';
import SiFlow from './Siflow';
import SiCalc from './Sicalc';
import Ticker from './Ticker'; // Import the Ticker component
import RefLinks from './RefLinks'; // Import the RefLinks component
import Footer from './Footer'; // Import the Footer component

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeComponent, setActiveComponent] = useState('SiTrader');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  // Render loading state until user data is fetched
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {user && (
        <Navbar
          user={user}
          handleComponentChange={handleComponentChange}
          activeComponent={activeComponent}
        />
      )}

      {activeComponent === 'SiTrader' && <Sitrader />}
      {activeComponent === 'SiFlow' && <SiFlow />}
      {activeComponent === 'SiCalc' && <SiCalc />}

      <Ticker
        messages={[
          'If you find value in SiTerminal consider signing up using one of our referral links!',
          '|',
          'It will greatly help to bring more features in the future!',
        ]}
        speed={30}
      />

      <RefLinks /> {/* Add the RefLinks component */}
      

      <Footer /> {/* Add the Footer component */}
    </div>
  );
};

export default Dashboard;