// src/components/Login.js
import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate, Link } from "react-router-dom";
import loginStyles from "./LoginStyle";
import logoImage from "../assets/images/sitrader_logo_1.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Store the user UID in sessionStorage
      sessionStorage.setItem("authToken", userCredential.user.uid);
      // Show the success popup
      setShowSuccessPopup(true);
      setErrorMessage("");
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate("/dashboard"); // Redirect to dashboard upon successful login
      }, 3000);
    } catch (error) {
      console.error("Login error:", error.message);
      setErrorMessage(parseFirebaseErrorMessage(error.message));
    }
  };

  const parseFirebaseErrorMessage = (errorMessage) => {
    const errorCode = errorMessage.slice(errorMessage.indexOf('/') + 1, errorMessage.indexOf(')'));
    switch (errorCode) {
      case "invalid-credential":
        return "error: Invalid credentials";
      case "user-not-found":
        return "error: User not found";
      case "invalid-email":
        return "error: Invalid email";
      default:
        return 'An error occurred. Please try again.';
    }
  };

  return (
    <div style={loginStyles.container(isMobile)}>
      <img src={logoImage} alt="Logo" style={loginStyles.logo} />
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={loginStyles.input}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={loginStyles.input}
        placeholder="Password"
      />
      <button onClick={handleLogin} style={loginStyles.button}>
        Login
      </button>
      {errorMessage && (
        <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>
      )}
      <p style={loginStyles.linkText}>
        New User? <Link to="/register" style={loginStyles.link}>Create Account Here</Link>
      </p>
      {showSuccessPopup && (
        <div style={loginStyles.notificationPopup}>
          <img
            src={logoImage}
            alt="Logo"
            style={loginStyles.notificationLogo}
          />
          <span style={loginStyles.notificationText}>
            Log in Successful
          </span>
        </div>
      )}
    </div>
  );
};

export default Login;